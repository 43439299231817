import React from "react";
import Title from "../layouts/Title";
import {
  registration,
  archeloLAB,
  library,
  Findmedsl,
  ecommerce,
  mp3player,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-start items-start  text-start">
        <Title title="I HAVE COLLABORATED WITH THESE" des="PROJECTS" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="ArcheloLAB Web Applicatione"
          des=" We made this application using React js, CSS, and Bootstrap for Archeolab company. They started a new platform for the architectures and we contributed for created the platform."
          src={archeloLAB}
          webLink="https://beta.archelolab.com/"
          link="https://github.com/ArcheloLAB/ArcheloLAB-Product"
        />
        <ProjectsCard
          title="Find Medicines"
          des=" We are making a findmeds platform using React js, Redux, and Node js for finding and donating medicines. This is a charity project."
          src={Findmedsl}
          link="https://github.com/IsuruMSoysa/findmedsl"
          webLink="https://findmeds.lk/"
        />
        <ProjectsCard
          title="Library Management Application"
          des=" I made this project using React js, Scss and Boostrap."
          src={library}
          link="https://github.com/Amila-siriwardhana/library"
        />
        <ProjectsCard
          title="My Benifitz Project"
          des=" We made a registration form using React.js following the foreign client requirements."
          src={registration}
          link="https://github.com/Amila-siriwardhana/mybenifitz_registration"
        />
        <ProjectsCard
          title="E-commerce Website"
          des=" We made E-commerce Site FrontEnd Development using React.js , Scss and Boostrap."
          src={ecommerce}
          link="https://github.com/Team-Sparks-200/Ecommerce_Site_FrontEnd"
        />
        <ProjectsCard
          title="Mp3 player"
          des=" Using tutorials and created an mp3 player to improve my knowledge using javascript."
          src={mp3player}
          link="https://github.com/Amila-siriwardhana/Mp3-Player"
        />
      </div>
    </section>
  );
};

export default Projects;

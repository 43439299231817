import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">Industry Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="React Based Industrial Training"
            subTitle="SOFTWARE ENGINEER TRAINING - (2021 - 2022)"
            result="USA"
            des="Work in an agile cross-functional team that owns the development lifecycle.Design and build front-end applications using JavaScript, React, Redux, Bootstrap and etc."
          />
          <ResumeCard
            title="Front End Developer"
            subTitle="AccSeedd (Pvt) Ltd- (2021)"
            result="MALAYSIA"
            des="Design and build front-end applications using JavaScript, React, Redux, Bootstrap and etc."
          />
          <ResumeCard
            title="Business Analysis Intern"
            subTitle="Loons Lab - (Aug 2021 - Feb 2022)"
            result="Oman"
            des="Design and build front-end applications using JavaScript, React, Redux, Bootstrap and etc"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;

import React from "react";

import { FaJava, FaJs, FaReact, FaSass } from "react-icons/fa";
import { SiTypescript, SiRedux, SiAmazonaws, SiDocker } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-10 border-b-[1px] border-b-black"
    >
      <Title title="I have worked on these" des="TECHNOLOGIES" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="React"
          des="With nearly 2 years of React development experience, I have delivered high-quality projects, adept at building custom components, implementing state management, and optimizing performance. Skilled and confident in adding value to any React-based project."
          icon={<FaReact />}
        />
        <Card
          title="Js"
          des="Experienced Javascript developer with 3+ years' expertise in diverse projects. Skilled in UI/UX, performance optimization, and staying current with industry trends. Committed to delivering exceptional results."
          icon={<FaJs />}
        />
        <Card
          title="Java"
          des="Followed OOP concepts using java and completed some mini projects"
          icon={<FaJava />}
        />

        <Card
          title="Sass"
          des="Create many projects using Sass since 2020. In React base industrial training used Sass."
          icon={<FaSass />}
        />
        <Card
          title="Typescript"
          des="Created some projects using typescript. I have nearly 2 years experienced with typescript with react."
          icon={<SiTypescript />}
        />
        <Card
          title="Redux"
          des="Mostly used for creating projects and I have done 3 projects using react-redux."
          icon={<SiRedux />}
        />
        <Card
          title="AWS"
          des="I have learned basics from the SLASSCOM cloud engineering boot camp."
          icon={<SiAmazonaws />}
        />
        <Card
          title="Docker"
          des="I following the docker study materials these days in docker and have got the basic knowledge."
          icon={<SiDocker />}
        />
      </div>
    </section>
  );
};

export default Features;

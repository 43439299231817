import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact } from "react-icons/fa";
import {
  SiTailwindcss,
  SiFigma,
  SiNextdotjs,
  SiRedux,
  SiCss3,
  SiHtml5,
  SiJava,
  SiSass,
  SiAmazonaws,
  SiDocker,
  SiGmail,
} from "react-icons/si";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Professional Coder.", "Front End Developer."],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY PORTFOLIO</h4>
        <h1 className="text-4xl font-bold text-white">
          Hi, I'm{" "}
          <span className="text-designColor capitalize">
            Amila D Siriwardhana
          </span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          An enthusiastic individual committed to learning and developing skills
          in web developing and team contribution; self-directed and energetic
          with superior performance in both autonomous or collaborative
          environments, while serving towards achieving organisational goals.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me on
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <a href="mailto:amilasiriwardhana660@gmail.com" target="_blank">
                <SiGmail />
              </a>
            </span>
            {/* <span className="bannerIcon">
              <FaTwitter />
            </span> */}
            <span className="bannerIcon">
              <a
                href="https://www.linkedin.com/in/amila-siriwardhana-5b207a183/"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
            </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            TECH STACK
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiRedux />
            </span>
            <span className="bannerIcon">
              <SiJava />
            </span>
            <span className="bannerIcon">
              <SiSass />
            </span>
            <span className="bannerIcon">
              <SiAmazonaws />
            </span>
            <span className="bannerIcon">
              <SiDocker />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
